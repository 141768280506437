import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import TitleAlt from '../Title/TitleAlt';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, imgTablet, paragraphOne, paragraphTwo, paragraphThree, paragraphFour, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col lg={6} md={12} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <article className="about-wrapper__info-text">
                  {paragraphOne}
                </article>
              </div>
            </Fade>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={'valueofday.jpg'} />
              </div>
            </Fade>
          </Col>
          <Col lg={12} id={'aboutWhyBackHz'}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <TitleAlt title="Why Back Hz?"/>
              <div className="about-wrapper__info">
                <article className="about-wrapper__info-text">
                  {paragraphTwo}
                </article>
                <article className="about-wrapper__info-text">
                  {paragraphThree}
                </article>
                <article className="about-wrapper__info-text">
                  {paragraphFour}
                </article>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
