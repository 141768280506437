import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import "@fontsource/shrikhand";

export default () => {
  const { title, lang, description, keywords, url } = headData;

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="Handheld Friendly" content='true' />
        <title>{title || 'Back Hz Sound'}</title>
        <html lang={lang || 'en'} />
        {/* <link rel='canonical' href="https://www.backhz.com" /> */}
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={description}/>
        <meta name="twitter:image" content="https://i.imgur.com/27y4xae.jpg"/>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:url" content="https://www.backhz.com" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description}/>
        <meta property="og:image" content="https://i.imgur.com/27y4xae.jpg"/>
        <meta property="og:url" content="https://www.backhz.com" />

        <meta name="description" content={description || 'Audio Post Production'} />
        <meta name="keywords" content={[...keywords]} />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
        <meta name="image" content="https://i.imgur.com/27y4xae.jpg" />

      </Helmet>
      <App />
    </>
  );
};
